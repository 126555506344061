@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&family=Ubuntu&display=swap");

$--c-brand-primary: #181732;
$--c-brand-secondary: #fff;

body,
html {
  background: $--c-brand-primary;
  display: flex;
  justify-content: center;
}

a.aLink {
  color: #333;
  transition: color 0.3s ease-in-out;
  &:active, &:visited {
    color: #333;
  }
  &:hover {
    color: #000;
  }
}

.App {
  width: 750px;

}

.app-inner {
  background: #fff;
  padding: 32px;
  border-radius: 4px;
  &.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  * {
    font-family: Arial;
  }
}

.home-item {
  $size: 128px;
  font-size: 12px;
  font-family: "Ubuntu", sans-serif;
  height: $size;
  width: $size;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0px 2px 2px #131B2E;
  background-color: $--c-brand-primary;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease;
  .fas {
    padding-bottom: 8px;
    font-size: 64px;
  }
  &:hover {
    box-shadow: 0px 2px 2px rgba(0,0,0,0);
    background: #5E4F9C;
  }
}

.back-home {
  text-decoration: none;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  position: absolute;
  margin-top: -52px;
  margin-left: -32px;
  color: $--c-brand-secondary;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .header-container {
    width: 800px;
  }
}

h1 {
  font-family: "Rajdhani", sans-serif;
  color: $--c-brand-secondary;
  font-size: 44px;
  text-align: center;
  margin-bottom: 0;
}

h2 {
  font-family: "Ubuntu", sans-serif;
  color: $--c-brand-secondary;
  font-size: 24px;
  text-align: center;
  margin-top: 0;
}

h3 {
  font-family: "Ubuntu", sans-serif;
  color: #89CCCA;
  font-size: 18px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 32px;
}

.inner-header {
  font-family: "Ubuntu", sans-serif;
  display: flex;
  flex-direction: column;

  .instructions {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #333;
    font-size: 12px;
    padding: 0 40px 32px;
  }

  .form-fields-container {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 8px;
    input, select {
      margin: 0 4px;
      padding: 8px 16px;
      font-size: 16px;
      border-radius: 4px;
      outline: none;
      border: 1px solid #ccc;
    }
  }
  .form-fields-container-brand {
    // flex-direction: column;
    // align-items: center;
  }
}

.actions {
  font-family: "Ubuntu", sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  .copy {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.btn-copy {
      cursor: pointer;
      border-radius: 4px;
      background-color: $--c-brand-primary;
      color: #fff;
      font-size: 18px;
      transition: background-color 0.3s ease-in-out;
      &:hover {
        background: lighten($--c-brand-primary, 15%);
        color: #fff;
      }
    }
    &.copied {
      font-size: 24px;
      color: $--c-brand-primary;
    }
  }
}

.signature-holder {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  display: none;
}

.signature-card {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
